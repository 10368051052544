.window {
  background-color: white;
  border-radius: 4px;
  margin: 10px;
  padding: 10px;
  max-height: calc(100% - 20px);
  overflow-y: auto;
  box-shadow: 3px 3px;

  opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.learn {
  width: calc(100% - 20px);
}

.image-carousel {
  height: 400px;
}
.carousel-indicators {
  bottom: -30px;
}

.image-content {
  display: flex;
  flex-direction: column;
  height: 400px;
  align-items: center;
  justify-content: center;
}

.fish-image {
  display: block;
  max-width:90%;
  max-height:340px;
  width: auto;
  height: auto;
}

.text-content {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-inner-content {
  max-width: 650px;
}

.text-content p {
  margin-bottom: 10px;
}

.location-chooser {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.reeling {
  user-select: none;
  opacity: 1;
	animation: backgroundFlicker 0.5s infinite;
}

@keyframes backgroundFlicker {
	0% {
		background-color: white;
	}
	50% {
    background-color: lightgray;
	}
}

.power-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 10px;
}

.power-segment {
  width: 10px;
  text-align: center;
  user-select: none;
}

.power-ready {
  color: red;
}

.reel-button {
  font-size: 28px;
}

.reel-button :active {
    background-color: #640c14;
}